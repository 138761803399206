<template>
  <div class="fill-view" v-loading="loading">
    <iframe ref="Iframe" class="fill-view2" :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  components: {},
  data() {
    return {
      src: '',
      loading: false
    };
  },
  created() {},
  mounted() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      axios.post('https://www.bjznyjny.com/api/iot/azl/findAzlInfo').then(e => {
        if (e.data && e.data.data) {
          this.src = e.data.data;
        }
      }).catch(error => {

      });
      this.iframeLoad();
    },
    iframeLoad() {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent('onload', () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    }
  }
};
</script>
<style scoped lang="scss">
.fill-view {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.fill-view2 {
  width: 100%;
  height: 100%;
}
</style>

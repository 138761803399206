<template>
  <div class="box">
    <el-form ref="addForm" :model="addForm" inline @submit.native.prevent>
      <el-form-item label="围栏名称">
        <el-input v-model="addForm.fenceName" placeholder="围栏名称" clearable />
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="resetForm()">重置</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-search" @click="getFindFenceList()">查询</el-button>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" icon="el-icon-plus" @click="handleControl('add', '')">新增围栏</el-button>
      </el-form-item>
    </el-form>
    <el-table :header-cell-style="{ background: '#F4F4F4' }" :data="findFenceListData" style="width: 100%;">
      <el-table-column label="序号" prop="index" type="index" width="55" align="center" />
      <el-table-column label="图标" prop="fenceIcon" min-width="55" align="center">
        <template slot-scope="scope">
          <img :src="require('../../../assets/njglicon/' + scope.row.fenceIcon + '.png')" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="fenceName" align="center" />
      <el-table-column label="围栏形状" prop="shapeType" align="center">
        <template slot-scope="scope">
          {{ scope.row.shapeType == 1 ? '圆形' : scope.row.shapeType == 2 ? '多边形' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="报警类型" prop="type" align="center">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? '进' : scope.row.type == 2 ? '出' : scope.row.type == 3 ? '进出' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="车辆数" prop="vkeys" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vkeys">{{ scope.row.vkeys.split(',').length }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="地图展示" prop="isShowMap" align="center">
        <template slot-scope="scope">
          {{ scope.row.isShowMap == 1 ? '是' : '否' }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="地址" prop="area" min-width="100" align="center" /> -->
      <el-table-column label="创建时间" prop="createTime" align="center" width="140" />

      <el-table-column label="操作" align="center" fixed="right" width="150">
        <template #default="scope">
          <!-- <el-button size="mini" type="info" @click="handleControl('show',scope.row.id)">详情</el-button> -->
          <el-button size="mini" type="primary" @click="handleControl('edit', scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDel(scope.row.thridId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="addForm.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="addForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addForm.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { postRequest } from '@/api/api.js';
export default {
  name: 'ElFenceList',
  components: {},
  data() {
    return {
      addForm: {
        fenceName: '',
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      findFenceListData: []
    };
  },
  mounted() {
    this.getFindFenceList();
  },

  methods: {
    // 新增地块 add edit show
    handleControl(type, row) {
      let that = this;
      if (type == 'edit') {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: row.shapeType,
            lat: row.lat,
            lng: row.lng,
            id: row.id,
            type: 'edit'
          }
        });
      } else if ((type = 'add')) {
        that.$router.push({
          path: 'elFence',
          query: {
            lat: 33.38,
            lng: 114.02,
            type: 'add'
          }
        });
      } else if ((type = 'show')) {
        that.$router.push({
          path: 'elFence',
          query: {
            shapeType: 2,
            lat: row.lat,
            lng: row.lng,
            type: 'show'
          }
        });
      }
    },
    handleDel(thridId) {
      let that = this;
      this.$confirm('此操作将删除该围栏, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postRequest('/api/iot/azl/deleteFence', { thridId: thridId }).then(res => {
            that.$message.success('删除成功');
            that.getFindFenceList();
          });
        })
        .catch(() => {});
    },
    resetForm() {
      let that = this;
      that.addForm.fenceName = '';
      that.getFindFenceList();
    },
    getFindFenceList() {
      let that = this;
      postRequest('/api/iot/azl/findFenceList', that.addForm).then(res => {
        that.findFenceListData = res.rows;
        that.addForm.total = res.total;
      }).catch(error => {

      });
    },
    handleSizeChange(val) {
      let that = this;
      that.addForm.pageSize = val;
      that.getFindFenceList();
    },
    handleCurrentChange(val) {
      let that = this;
      that.addForm.pageNum = val;
      that.getFindFenceList();
    }
  }
};
</script>
<style scoped lang="scss">
h2,
div,
body,
html,
table,
th,
tr,
td,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.pagination {
  text-align: right;
  padding: 10px 0;
}
</style>

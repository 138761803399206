<template>
  <div class="box">
    <!-- <div class="btn-fullscreen" @click="handleFullScreen">
            <el-tooltip effect="dark" :content="fullscreen ? `退出全屏` : `全屏`" placement="bottom">
                <i class="el-icon-rank"></i>
            </el-tooltip>
        </div> -->
    <div class="close" :class="closeIs ? '' : 'open'" @click="close()"></div>
    <div class="DrawerBox" :class="closeIs ? '' : 'open'">
      <h2>{{ type == 'edit' ? '编辑围栏' : '新增围栏' }}</h2>
      <div class="formBox">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="搜索地址" placeholder="省、市、区、街道">
            <div class="searchBox">
              <el-input placeholder="请输入搜索地址" prefix-icon="el-icon-search" @input="aMap()" v-model="ruleForm.searchText"> </el-input>
              <ul v-if="searchIs">
                <li v-for="(item, index) in searchResult" :key="index" @click="goTo(item)"
                  >{{ item.name }}<span>{{ item.address }}</span></li
                >
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="围栏图标" prop="fenceIcon">
            <el-select v-model="ruleForm.fenceIcon" placeholder="请选择围栏图标" clearable popper-class="weiIcon">
              <el-option label="围栏" value="1"> <img src="../../../assets/njglicon/1.png" alt="" /><span class="row">围栏</span> </el-option>
              <el-option label="修理厂" value="2"> <img src="../../../assets/njglicon/2.png" alt="" /><span class="row">修理厂</span> </el-option>
              <el-option label="加油站" value="3"> <img src="../../../assets/njglicon/3.png" alt="" /><span class="row">加油站</span> </el-option>
              <el-option label="家" value="4"> <img src="../../../assets/njglicon/4.png" alt="" /><span class="row">家</span> </el-option>
              <el-option label="学校" value="5"> <img src="../../../assets/njglicon/5.png" alt="" /><span class="row">学校</span> </el-option>
              <el-option label="医院" value="6"> <img src="../../../assets/njglicon/6.png" alt="" /><span class="row">医院</span> </el-option>
              <el-option label="公司" value="7"> <img src="../../../assets/njglicon/7.png" alt="" /><span class="row">公司</span> </el-option>
              <el-option label="火车站" value="8"> <img src="../../../assets/njglicon/8.png" alt="" /><span class="row">火车站</span> </el-option>
              <el-option label="码头" value="9"> <img src="../../../assets/njglicon/9.png" alt="" /><span class="row">码头</span> </el-option>
              <el-option label="机场" value="10"> <img src="../../../assets/njglicon/10.png" alt="" /><span class="row">机场</span> </el-option>
              <el-option label="停车场" value="11"> <img src="../../../assets/njglicon/11.png" alt="" /><span class="row">停车场</span> </el-option>
              <el-option label="检查站" value="12"> <img src="../../../assets/njglicon/12.png" alt="" /><span class="row">检查站</span> </el-option>
              <el-option label="其他" value="13"> <img src="../../../assets/njglicon/13.png" alt="" /><span class="row">其他</span> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="围栏形状">
            <el-select v-model="ruleForm.shapeType" :disabled="type == 'edit' ? true : false" placeholder="请选择" @change="shapeTypeChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="围栏名称" prop="fenceName" placeholder="请输入围栏名称">
            <el-input v-model="ruleForm.fenceName" clearable></el-input>
          </el-form-item>
          <el-form-item label="感应范围" v-if="ruleForm.shapeType == 1">
            <el-slider v-model="ruleForm.gpsRange" show-input :min="100" :max="5000" @change="sliderFun" @input="sliderFun"></el-slider>
          </el-form-item>
          <el-form-item label="进出警告设置" prop="typeBack">
            <el-checkbox-group v-model="ruleForm.typeBack">
              <el-checkbox label="进"></el-checkbox>
              <el-checkbox label="出"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="显示在地图" prop="isShowMap">
            <el-radio-group v-model="ruleForm.isShowMap">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择车辆" prop="name">
            <el-input v-model="ruleForm.vkeysLength" :disabled="true"></el-input>
            <el-button @click="getCarFun" type="text">添加车辆</el-button>
          </el-form-item>
          <div class="btn">
            <el-form-item>
              <el-button @click="resetForm('ruleForm')">返 回</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div id="map"></div>
    <el-drawer class="DrawerBox1" title="请选择车辆" size="370px" :visible.sync="drawer" :direction="direction" :before-close="cancelForm">
      <el-table
        :data="carInfoListData"
        :row-key="getRowKey"
        ref="multipleTable"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F4F4' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="100" align="center" :reserve-selection="true" />
        <el-table-column prop="carNum" label="车辆" align="center" />
      </el-table>
      <div class="btn">
        <el-button type="primary" @click="handleClose()">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
// import 'leaflet.path.drag';

import { postRequest } from '@/api/api.js';
export default {
  name: 'ElFence',
  components: {},
  data() {
    return {
      type: this.$route.query.type,
      map: '', //地图
      drawControl: '', //地图工具条
      latlngs: [], //组装围栏数组
      polygon: '', //围栏对象
      marker: [], //长度数组
      fullscreen: false, //是否全屏
      _isEdit: false, //是否编辑过
      markerIcon: '', //标记
      circle: '',

      closeIs: true,
      ruleForm: {
        searchText: '',
        fenceName: '', // 围栏名称
        lat: this.$route.query.lat || 33.38, //纬度
        lng: this.$route.query.lng || 114.02, //经度
        fenceIcon: '', //围栏图标
        vkeys: null, //当前围栏绑定车辆列表  多辆车以 英文逗号 分割 ，取值来自车辆列表接口里的 vkey 字段
        vkeysLength: '',
        type: [], //围栏监控类型  1 进   2 出  3 进出
        typeBack: [],
        shapeType: this.$route.query.shapeType || 2, // 围栏类型： 1 圆形  2 多边形 3 行政区域 4 轨迹 其中 3，4 暂不支持
        pointsBack: [], //围栏信息
        points: [],
        gpsRange: 100, //围栏半径，当围栏类型为 圆形时，必填
        gpsType: 1, //  可以填为固定值
        paramGpsType: 1, //  可以填为固定值
        isShowMap: '1', //是否需要在地图上展示当前围栏  1 需要 0 不需要
        area: 0, //围栏面积
        girth: 0 //地块周长
      },
      rules: {
        fenceName: [{ required: true, message: '请输入围栏名称', trigger: 'blur' }],
        fenceIcon: [{ required: true, message: '请选择围栏图标', trigger: 'change' }],
        typeBack: [{ required: true, message: '请选择进出警告设置', trigger: 'change' }]
      },
      drawer: false,
      direction: 'rtl',
      searchResult: [],
      searchIs: false, //是否显示结果
      carInfoListData: [],
      options: [
        {
          value: 1,
          label: '圆形'
        },
        {
          value: 2,
          label: '多边形'
        }
      ]
    };
  },
  mounted() {
    this.initMap();
    if (this.$route.query.type == 'add') {
      this.drawLand();
    } else if (this.$route.query.type == 'edit') {
      this.getFindFenceDetail();
    }
  },
  computed: {},
  watch: {},
  methods: {
    getCarFun() {
      this.drawer = true;
      this.getCarInfoList();
    },
    shapeTypeChange() {
      let that = this;
      if (that.polygon) that.map.removeLayer(that.polygon);
      if (that.circle) that.map.removeLayer(that.circle);
      that.clearLayers();
      that.drawLand();
    },
    getFindFenceDetail() {
      let that = this;
      postRequest('/api/iot/azl/findFenceDetail', { id: that.$route.query.id }).then(res => {
        that.ruleForm = { ...that.ruleForm, ...res };
        if (that.ruleForm.type == 3) {
          that.ruleForm.typeBack = ['进', '出'];
        } else if (that.ruleForm.type == 2) {
          that.ruleForm.typeBack = ['出'];
        } else if (that.ruleForm.type == 1) {
          that.ruleForm.typeBack = ['进'];
        }
        if (that.ruleForm.points) {
          let arr = [];
          that.ruleForm.points = that.ruleForm.points.substring(0, that.ruleForm.points.length - 1);
          that.ruleForm.points.split(';').forEach((v, i) => {
            arr.push({
              lng: parseFloat(v.split(',')[0]),
              lat: parseFloat(v.split(',')[1])
            });
          });
          that.ruleForm.pointsBack = arr;
        }
        that.ruleForm.isShowMap = that.ruleForm.isShowMap.toString();
        if (that.ruleForm.vkeys) {
          that.ruleForm.vkeysLength = '已选择' + that.ruleForm.vkeys.split(',').length + '辆车辆';

          // that.ruleForm.vkeys.split(';').forEach((v,i) => {
          //     that.$refs.table.toggleRowSelection(v,true);
          // })
          // that.$refs.table.toggleRowSelection(that.carInfoListData[1],true);
          // for(let i=0;i<this.lists.length;i++){
          //     if(this.lists[i].style == this.radio){
          //         this.$refs.table.toggleRowSelection(this.lists[i],true);
          //     }
          // }
        }
        that.drawLand();
      }).catch(error => {

      });
    },
    toggleSelection(rows) {
      if (this.ruleForm.vkeys) {
        this.$nextTick(() => {
          this.ruleForm.vkeys.split(',').forEach((row, index) => {
            this.$refs.multipleTable.toggleRowSelection(
              this.carInfoListData.find(tx => {
                return tx.vkey == row;
              }),
              true
            );
          });
        });
        // this.carInfoListData.forEach((r, i) => {
        //     if(string.indexOf(r.vkey) > -1){
        //         this.$refs.multipleTable.toggleRowSelection(this.carInfoListData[i], true);
        //     }
        // })
        // string.forEach((row, index) => {
        // this.$nextTick(() => {
        //     this.$refs.multipleTable.toggleRowSelection(rows[1], true);
        // })
        // });
      }
    },
    newArr(arr) {
      return [...new Set(arr)];
    },
    getRowKey(row) {
      return row.id;
    },
    // 表格多选触发
    handleSelectionChange(val) {
      // console.log(val.length)
      // var result = [];
      // var obj = {};
      // for (var i = 0; i < val.length; i++) {
      //     if (!obj[val[i].vkey]) {
      //         result.push(val[i]);
      //         obj[val[i].vkey] = true;
      //     }
      // }
      // console.log(123,result);
      let arr = [];
      val.forEach(v => {
        arr.push(v.vkey);
      });
      this.ruleForm.vkeys = arr.join(',');
      this.ruleForm.vkeysLength = '已选择' + val.length + '辆车辆';
    },
    getCarInfoList() {
      let that = this;
      let data = {
        carNum: '', //车牌号 非必填
        pageNum: 1, //页码   非必填
        pageSize: 20 //每页数量   非必填
      };
      postRequest('/api/iot/azlCar/carInfoList', data).then(res => {
        that.carInfoListData = res.rows;
        that.toggleSelection(that.carInfoListData);
      }).catch(error => {

      });
    },
    aMap() {
      let that = this;
      AMap.service('AMap.PlaceSearch', function() {
        var placeSearch = new AMap.PlaceSearch({
          city: '全国'
        });
        placeSearch.search(that.ruleForm.searchText, function(status, result) {
          that.searchResult = result.poiList.pois;
          that.searchIs = true;
        });
      });
    },
    goTo(item) {
      let that = this;
      if (that.polygon) that.map.removeLayer(that.polygon);
      if (that.circle) that.map.removeLayer(that.circle);
      that.ruleForm.pointsBack = [];
      that.clearLayers();
      that.ruleForm.lat = item.location.lat;
      that.ruleForm.lng = item.location.lng;
      that.drawLand();
      that.searchText = item.name;
      that.searchIs = false;
      if (that.ruleForm.shapeType == 2) {
        that.map.fitBounds(that.polygon.getBounds(), { padding: [200, 200] });
      } else if (that.ruleForm.shapeType == 1) {
        that.circle.setRadius(that.ruleForm.gpsRange);
        that.map.fitBounds(that.circle.getBounds(), { padding: [200, 200] });
      }
    },
    sliderFun(e) {
      if (this.ruleForm.shapeType == 1 && this.circle) {
        this.circle.setRadius(e);
        this.map.fitBounds(this.circle.getBounds(), { padding: [200, 200] });
      }
    },
    handleClose(done) {
      this.drawer = false;
    },
    cancelForm() {
      this.drawer = false;
    },
    close() {
      this.closeIs = !this.closeIs;
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          let sting = '';
          that.ruleForm.pointsBack.forEach((v, i) => {
            sting += v.lng + ',' + v.lat + ';';
          });
          that.ruleForm.points = sting;

          if (that.ruleForm.typeBack.length > 1) {
            that.ruleForm.type = 3;
          } else if (that.ruleForm.typeBack[0] == '进') {
            that.ruleForm.type = 1;
          } else if (that.ruleForm.typeBack[0] == '出') {
            that.ruleForm.type = 2;
          }

          if (that.$route.query.type == 'edit') {
            postRequest('/api/iot/azl/fenceUpdate', that.ruleForm).then(res => {
              that.$router.push({
                path: 'elFenceList',
                query: {}
              });
            }).catch(error => {

      });
          } else {
            postRequest('/api/iot/azl/fenceAdd', that.ruleForm).then(res => {
              that.$router.push({
                path: 'elFenceList',
                query: {}
              });
            }).catch(error => {

      });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$router.push({
        path: 'elFenceList',
        query: {}
      });
    },
    initMap() {
      let that = this;
      that.map = L.map('map', {
        minZoom: 3,
        maxZoom: 19,
        center: [38, 106],
        zoom: 4.2,
        zoomControl: true,
        attributionControl: false,
        zoomSnap: 1,
        crs: L.CRS.EPSG3857
      });
      L.tileLayer('https://wprd02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7', { maxZoom: 22, minZoom: 1 }).addTo(
        that.map
      );
      // 地图基础参数
      var drawnItems = new L.FeatureGroup();
      that.map.addLayer(drawnItems);
      that.drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          polygon: false,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false
        }
      });
      that.map.addControl(that.drawControl);
    },
    drawLand() {
      let that = this;
      // 多边形
      if (that.ruleForm.shapeType == 2) {
        if (that.ruleForm.pointsBack.length == 0) {
          that.ruleForm.pointsBack = [
            {
              lat: parseFloat(that.ruleForm.lat) + 0.001,
              lng: parseFloat(that.ruleForm.lng) - 0.001
            },
            {
              lat: parseFloat(that.ruleForm.lat) + 0.001,
              lng: parseFloat(that.ruleForm.lng) + 0.001
            },
            {
              lat: parseFloat(that.ruleForm.lat) - 0.001,
              lng: parseFloat(that.ruleForm.lng) + 0.001
            },
            {
              lat: parseFloat(that.ruleForm.lat) - 0.001,
              lng: parseFloat(that.ruleForm.lng) - 0.001
            }
          ];
        }
        let path = [];
        let lat = 0;
        let lng = 0;
        // 围栏制作
        that.latlngs = [];
        that.ruleForm.pointsBack.forEach((v, i) => {
          //画图地块参数
          that.latlngs.push([v.lat, v.lng]);
          path.push({
            lat: v.lat,
            lng: v.lng
          });
          // 渲染长度
          if (i == that.ruleForm.pointsBack.length - 1) {
            let pt1 = [v.lat, v.lng];
            let pt2 = [that.ruleForm.pointsBack[0].lat, that.ruleForm.pointsBack[0].lng];
            that.returnSlideDistance(that.map, pt1, pt2);
          } else {
            let pt1 = [v.lat, v.lng];
            let pt2 = [that.ruleForm.pointsBack[i + 1].lat, that.ruleForm.pointsBack[i + 1].lng];
            that.returnSlideDistance(that.map, pt1, pt2);
          }
          //地块中心经纬度
          lat += v.lat;
          lng += v.lng;
        });
        // 计算面积
        that.ruleForm.area = that.computeSignedArea(path);
        //地块中心计算
        that.ruleForm.lat = lat / that.ruleForm.pointsBack.length;
        that.ruleForm.lng = lng / that.ruleForm.pointsBack.length;

        that.polygon = L.polygon(that.latlngs, {
          color: '#409eff',
          fillColor: '#409eff',
          weight: 2,
          opacity: 1,
          fillOpacity: 0.5,
          draggable: true
        });
        that.map.addLayer(that.polygon);
        that.polygon.editing.enable();
        that.polygon.on('dragstart', function(event) {
          that.clearLayers();
          that.polygon.editing.disable();
        });
        that.polygon.on('dragend', function(event) {
          that.polygon.editing.enable();
          let lat = 0;
          let lng = 0;
          that.ruleForm.pointsBack = [];
          event.target._latlngs[0].forEach((v, i) => {
            that.ruleForm.pointsBack.push({
              lng: v.lng,
              lat: v.lat,
              pointOrder: i + 1
            });
            //地块中心经纬度
            lat += parseFloat(v.lat);
            lng += parseFloat(v.lng);
            // 渲染长度
            if (i == event.target._latlngs[0].length - 1) {
              let pt1 = [v.lat, v.lng];
              let pt2 = [event.target._latlngs[0][0].lat, event.target._latlngs[0][0].lng];
              that.returnSlideDistance(that.map, pt1, pt2);
            } else {
              let pt1 = [v.lat, v.lng];
              let pt2 = [event.target._latlngs[0][i + 1].lat, event.target._latlngs[0][i + 1].lng];
              that.returnSlideDistance(that.map, pt1, pt2);
            }
          });
          //地块中心计算
          that.ruleForm.lat = lat / event.target._latlngs[0].length;
          that.ruleForm.lng = lng / event.target._latlngs[0].length;
        });

        // 障碍物标记
        // let myIcon=L.icon({
        //     iconUrl: require('../../../assets/njglicon/1.png'),
        //     iconSize: [32,32],
        // });
        // that.markerIcon = L.marker([that.mapData.lat,that.mapData.lng], {
        //     icon: myIcon,
        //     opacity: '1',
        //     // draggable: true
        // }).addTo(that.map)
        // that.markerIcon.on('dragstart', function (event) {
        //     console.log('dragstart', event.target._latlng);
        // })
        // that.markerIcon.on('dragend', function (event) {
        //     console.log('dragend', event.target._latlng);
        // })
        that.map.fitBounds(that.polygon.getBounds(), { padding: [200, 200] });

        // that.map.on('draw:created',function(e) {
        //     // console.log('created',e)
        //     // that.createdMap(e)
        // });
        that.map.on('draw:editvertex', function(e) {
          // console.log('editvertex',e)
          that.editvertex(e);
        });
      }
      // 圆形
      else if (that.ruleForm.shapeType == 1) {
        that.circle = L.circle([that.ruleForm.lat, that.ruleForm.lng], {
          color: '#409eff',
          fillColor: '#409eff',
          radius: 100,
          weight: 2,
          opacity: 1,
          fillOpacity: 0.5
        });
        that.map.addLayer(that.circle);
        that.circle.editing.enable();
        // that.circle.editing.disable()
        that.map.fitBounds(that.circle.getBounds(), { padding: [200, 200] });
        that.map.on('draw:editresize', function(e) {
          that.ruleForm.gpsRange = that.circle.getRadius();
          // that.map.fitBounds(that.circle.getBounds(),{ padding: [200, 200] });
        });
        that.map.on('draw:editmove', function(e) {
          that.ruleForm.lat = e.layer._latlng.lat;
          that.ruleForm.lng = e.layer._latlng.lng;
          // that.map.fitBounds(that.circle.getBounds(),{ padding: [200, 200] });
        });
      }
    },
    editvertex(e) {
      let that = this;
      let _layers = e.poly._latlngs[0];
      that.ruleForm.girth = 0;
      let path = [];
      that.ruleForm.pointsBack = [];
      that.ruleForm.lat = 0;
      that.ruleForm.lng = 0;
      _layers.forEach((v, i) => {
        //面积参数
        path.push({
          lat: v.lat,
          lng: v.lng
        });
        that.ruleForm.pointsBack.push({
          lng: v.lng,
          lat: v.lat,
          pointOrder: i + 1
        });
        //地块中心经纬度
        that.ruleForm.lat += v.lat;
        that.ruleForm.lng += v.lng;
      });
      // 计算面积
      that.ruleForm.area = that.computeSignedArea(path);
      //地块中心计算
      that.ruleForm.lat = that.ruleForm.lat / _layers.length;
      that.ruleForm.lng = that.ruleForm.lng / _layers.length;
      that.girthFun();
    },
    girthFun() {
      let that = this;
      // 删除边长
      this.marker = [];
      if (this.markerGroup) this.markerGroup.clearLayers();

      // 重新渲染地块边长
      that.ruleForm.girth = 0;
      that.ruleForm.pointsBack.forEach((v, i) => {
        // 渲染长度
        if (i == that.ruleForm.pointsBack.length - 1) {
          let pt1 = [v.lat, v.lng];
          let pt2 = [that.ruleForm.pointsBack[0].lat, that.ruleForm.pointsBack[0].lng];
          that.returnSlideDistance(that.map, pt1, pt2);
        } else {
          let pt1 = [v.lat, v.lng];
          let pt2 = [that.ruleForm.pointsBack[i + 1].lat, that.ruleForm.pointsBack[i + 1].lng];
          that.returnSlideDistance(that.map, pt1, pt2);
        }
      });
    },
    computeSignedArea(path) {
      let that = this;
      let radius = 6371009;
      let len = path.length;
      if (len < 3) return 0;
      let total = 0;
      let prev = path[len - 1];
      let prevTanLat = Math.tan((Math.PI / 2 - (prev.lat / 180) * Math.PI) / 2);
      let prevLng = (prev.lng / 180) * Math.PI;
      for (let i in path) {
        let tanLat = Math.tan((Math.PI / 2 - (path[i].lat / 180) * Math.PI) / 2);
        let lng = (path[i].lng / 180) * Math.PI;
        total += that.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
        prevTanLat = tanLat;
        prevLng = lng;
      }
      return parseFloat(Math.abs(total * (radius * radius)).toFixed(2));
    },
    polarTriangleArea(tan1, lng1, tan2, lng2) {
      let deltaLng = lng1 - lng2;
      let t = tan1 * tan2;
      return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    },
    returnSlideDistance(map, pt1, pt2) {
      let length = map.distance(pt1, pt2);
      let distance = Math.abs(parseFloat(length.toFixed(2)));
      let lat = (Number(pt1[0]) + Number(pt2[0])) / 2;
      let lng = (Number(pt1[1]) + Number(pt2[1])) / 2;
      this.ruleForm.girth += distance;
      let icon = L.divIcon({
        className: 'distanceContent',
        html: `<span class="distanceTip">${distance}米</span>`,
        iconSize: [0, 0]
      });
      let layer = L.marker([lat, lng], { icon: icon }).addTo(map);
      this.marker.push(layer);
      this.markerGroup = L.layerGroup(this.marker);
      map.addLayer(this.markerGroup);
    },
    clearLayers(type) {
      let that = this;
      // 各个边长
      this.marker = [];
      if (this.markerGroup) this.markerGroup.clearLayers();

      // if(that.polygon) that.map.removeLayer(that.polygon);
      // if(that.circle) that.map.removeLayer(that.circle);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>
<style scoped lang="scss">
h2,
div,
body,
html,
table,
th,
tr,
td,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
}
// 右侧内容
.DrawerBox {
  width: 370px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #fff;
  h2 {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    line-height: 40px;
    text-align: left;
    padding: 0 10px;
  }
  .formBox {
    width: 350px;
    text-align: left;
    margin: 10px 0 0 0;
    .el-form-item {
      margin-bottom: 20px;
    }
  }
  .btn {
    margin: 50px 0 0 0;
  }
  z-index: 101;
}
.close {
  content: '';
  position: absolute;
  right: 370px;
  top: 50%;
  margin: -107px 0 0 0;
  background: url(../../../assets/njglicon/close.png);
  width: 28px;
  height: 214px;
  cursor: pointer;
  z-index: 102;
}
.DrawerBox.open {
  display: none;
}
.close.open {
  right: 0px;
  background: url(../../../assets/njglicon/open.png);
  z-index: 102;
}
.weiIcon .row {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 5px;
}
.weiIcon img {
  width: 23px;
  vertical-align: middle;
}

// 右侧弹窗
.DrawerBox1 {
  .btn {
    margin: 50px 0;
    text-align: center;
  }
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  ::v-deep .leaflet-left .leaflet-control-zoom {
    display: none;
  }
}
/* 地图相关 */
::v-deep .leaflet-div-icon {
  border-radius: 20px;
  border: 2px solid #409eff;
  // position: relative;
}
::v-deep .distanceTip {
  display: inline-block;
  word-break: keep-all;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1px 3px;
  line-height: 1;
  border-radius: 2px;
  transform: translate(-50%, -150%);
  position: relative;
  z-index: -1;
}
::v-deep .leaflet-marker-icon:last-child {
  display: none;
}
.btn-fullscreen {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 200;
}
.searchBox {
  position: relative;

  ul {
    position: absolute;
    border-radius: 3px;
    overflow: hidden;
    width: 230px;
    background: #fff;
    z-index: 99;
    left: 0px;
    top: 40px;
    box-shadow: 0 0 5px #999;
    li {
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      text-indent: 10px;
      border-bottom: 1px solid #ddd;
      overflow: hidden;
      font-size: 14px;
      span {
        color: #999;
        font-size: 12px;
        margin-left: 4px;
      }
    }
    li:hover {
      background: #eee;
    }
  }
}
</style>
